import * as React from "react";
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import styled from "styled-components";
import Link from "@mui/material/Link";
import Container from "@mui/material/Container";

const P = styled.p`
  font-size: 24px;
  line-height: 32px;
  margin: 0px;
  margin-bottom: 12px;
  letter-spacing: 1.3px;
`;

const items = [
  {
    title: "What is Fore Edge Book Art?",
    content: (
      <>
        <P>
          <b>Fore Edge Book Art</b> is a creative technique that transforms the
          edges of a book's pages into a stunning display of your custom image.{" "}
          <br />
          Using your chosen image, our Pattern Maker generates a perfectly
          tailored pattern for your book. The process involves cutting, folding,
          and gluing strips of the pattern onto the page edges. When the book is
          fanned open, the image is revealed—turning an ordinary book into a
          breathtaking work of art. <br />
          It's a beautiful and personal way to celebrate your favorite book or
          create a one-of-a-kind gift that's sure to captivate and impress!
        </P>
      </>
    ),
  },
  {
    title: "How much does it cost?",
    content: (
      <>
        <P>
          At ForeEdgeBookArt.com, we offer flexible pricing options to suit your
          needs:
        </P>
        <P>
          • <b>Monthly Subscription</b>: $9.99 CAD <br /> •{" "}
          <b>Yearly Subscription</b>: $99.99 CAD (Save $20 annually!) <br />
          Both subscription plans give you <b>unlimited access</b> to the
          Pattern Maker and all its features. Perfect for creating as many
          custom designs as your heart desires!
        </P>
        <P>
          Not ready for a subscription? No problem! You can purchase{" "}
          <b>single patterns</b> for just $6.99 CAD each. <br />
        </P>
        <P>
          Whether you're a casual crafter or a passionate book artist, we've got
          an option that works for you.
        </P>
      </>
    ),
  },
  {
    title: "What payment methods does Fore Edge Book Art support?",
    content: (
      <>
        <P>
          ForeEdgeBookArt.com makes it easy to pay securely using Stripe, our
          trusted payment processor. We accept:
        </P>
        <P>
          • <b>Credit Cards</b>: Visa, Mastercard, American Express, Discover,
          Diners Club, JCB, and China UnionPay—accepted from customers
          worldwide. <br />• <b>Digital Wallets</b>: Apple Pay and Google Pay.{" "}
          <br />• <b>Link</b>: For seamless, secure checkout experiences. <br />
        </P>
        <P>
          No matter where you're located, we've got a payment option to suit
          you. Craft with confidence knowing your payments are safe and secure!
        </P>
      </>
    ),
  },
  {
    title: "How many patterns can I create with the Pattern Maker?",
    content: (
      <>
        <P>
          With a subscription, you can create <b>unlimited patterns</b>—explore
          your creativity without limits!
          <br />
          Prefer to start small? We also offer the option to purchase{" "}
          <b>single patterns</b> if you're not ready for a subscription.
        </P>
      </>
    ),
  },
  {
    title: "How do I create a pattern?",
    content: (
      <>
        <P>Creating a pattern with ForeEdgeBookArt.com is simple and fun!</P>
        <P>
          1. <b>Upload your image</b> to the Pattern Maker. <br />
          2. <b>Enter your book's details</b>, including the height and the last
          odd page number. <br />
          3. <b>Generate your custom pattern.</b> The Pattern Maker will tailor
          it perfectly to fit your book and image. <br />
          4. <b>Download your pattern</b>—and you're ready to start your
          project! <br />
        </P>
        <P>
          In just a few easy steps, you'll have a unique pattern that's ready to
          bring your vision to life.
        </P>
      </>
    ),
  },
  {
    title: "What is a Lenticular pattern?",
    content: (
      <>
        <P>
          A Lenticular pattern allows you to display two different images on the
          edges of a book.
        </P>
        <P>
          • The <b>first image</b> appears when the fanned edges are viewed from
          one direction. <br />• The <b>second image</b> reveals itself when
          viewed from the other direction.
        </P>
        <P>
          It's a dynamic and eye-catching way to showcase multiple images on a
          single book, making your creation even more unique and impressive!
        </P>
      </>
    ),
  },
  {
    title: "Can I use any book?",
    content: (
      <>
        <P>
          Hardcover books with <b>smooth, uniform edges</b> are ideal for Fore
          Edge Book Art. <br />
          Our Pattern Maker supports books ranging from{" "}
          <b>2.5 cm to 28 cm in height</b> (approximately{" "}
          <b>1 inch to 11 inches</b>), giving you plenty of flexibility to
          choose the perfect book for your project.
        </P>
      </>
    ),
  },
  {
    title: "From start to finish, what does the process look like?",
    content: (
      <>
        <P>
          Our vibrant Facebook group is the perfect place to dive into the
          process! With <b>thousands of members</b>, you'll find:
        </P>
        <P>
          • Step-by-step resources and videos shared by passionate crafters.{" "}
          <br />• A supportive community eager to help and discuss everything
          about Fore Edge Book Art. <br />• Inspiration and tips from people at
          every skill level.
        </P>
        <P>
          Join the conversation, explore the resources, and connect with others
          who share your love for this creative craft. It's the best way to
          learn and grow your skills!
        </P>
        <P>
          <Link href="https://www.facebook.com/share/g/15LzBAKnYB/">
            Join our Facebook group for tutorials, tips, and support.
          </Link>
        </P>
      </>
    ),
  },
];

const FaqItem = ({ title, children, idx, expandedIdx, handleChange }) => {
  return (
    <Accordion expanded={expandedIdx === idx} onChange={handleChange(idx)}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        style={{ fontSize: "28px" }}
        sx={{
          "&.Mui-expanded .MuiAccordionSummary-content": {
            marginTop: "12px",
            marginBottom: "12px",
          },
        }}
      >
        {title}
      </AccordionSummary>
      <AccordionDetails sx={{ px: 3, pb: 3 }}>{children}</AccordionDetails>
    </Accordion>
  );
};

export const Faq = () => {
  const [expandedIdx, setExpandedIdx] = React.useState(null);

  const handleChange = (idx) => (event, isExpanded) => {
    setExpandedIdx(isExpanded ? idx : null);
  };

  return (
    <Container
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        alignContent: "center",
      }}
    >
      {/* Title */}
      <Typography
        variant="h2"
        style={{
          textAlign: "center",
          lineHeight: "42px",
          marginBottom: "20px",
        }}
      >
        Frequently Asked Questions
      </Typography>
      {/* Questions */}
      {items.map((item, idx) => (
        <FaqItem
          title={item.title}
          idx={idx}
          expandedIdx={expandedIdx}
          handleChange={handleChange}
          key={idx}
        >
          {item.content}
        </FaqItem>
      ))}
    </Container>
  );
};
