import React, { createContext, useState } from "react";
import { Typography, Modal, Button } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import confetti from "canvas-confetti";

export const FreePatternModalContext = createContext();

export const FreePatternModalProvider = (props) => {
  const [showModal, setShowModal] = useState(false);
  return (
    <FreePatternModalContext.Provider
      value={{ setShowFreePattern: setShowModal }}
    >
      {props.children}
      {showModal && (
        <FreePatternModal
          callback={() => {
            setShowModal(false);
          }}
        />
      )}
    </FreePatternModalContext.Provider>
  );
};

const FreePatternModal = (props) => {
  const handleClose = () => {
    props.callback();
  };

  React.useEffect(() => {
    confetti({
      particleCount: 300,
      spread: 160,
      zIndex: 1500,
    });
  }, []);

  return (
    <Modal
      open={true}
      onClose={handleClose}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backdropFilter: "blur(3px)",
      }}
      disableAutoFocus={true}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: "white",
          padding: "20px",
          borderRadius: "15px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }}
        >
          <IconButton
            edge="end"
            color="inherit"
            aria-label="Menu"
            onClick={handleClose}
            style={{
              alignSelf: "flex-end",
              paddingTop: "0px",
              paddingBottom: "0px",
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <Typography variant="h3">Welcome to</Typography>
        <Typography
          sx={{
            typography: { xs: "h3", sm: "h2", md: "h1" },
            textAlign: "center",
            fontWeight: 500,
            letterSpacing: "5px",
            color: "#4B2E39",
            lineHeight: { xs: "30px", sm: "45px", md: "60px" },
          }}
        >
          Fore Edge Book Art
        </Typography>

        <img
          src={`${process.env.PUBLIC_URL}/images/FreePattern-Finished.png`}
          alt={"Free Fore Edge Book Art Pattern"}
          style={{
            maxHeight: "30vh",
            minWidth: "250px",
          }}
        />

        <Typography variant="h4">Click below for your free pattern!</Typography>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "20px",
          }}
        >
          <Button
            href={`${process.env.PUBLIC_URL}/patterns/FreePattern1.pdf`}
            variant={"contained"}
            size="large"
            download
            onClick={handleClose}
            style={{ minWidth: "100px", backgroundColor: "#83c021" }}
          >
            <Typography variant="h5" style={{ marginBottom: "-5px" }}>
              Download Free Pattern
            </Typography>
          </Button>
        </div>
      </div>
    </Modal>
  );
};
