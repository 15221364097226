import * as React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { SnackbarProvider } from "./Context/SnackBarProvider";
import { FreePatternModalProvider } from "./Context/FreePatternModalProvider";
import { AuthProvider } from "./Context/AuthProvider";
import { FirebaseAppProvider } from "./Context/FirebaseAppProvider";
import { RemoteConfigProvider } from "./Context/RemoteConfigProvider";
import { SignedInCheck } from "./Components/Account/SignedInCheck";
import { SinglePattern } from "./Components/SinglePattern";
import Main from "./Components/Main/Index";
import { LandingPage } from "./Components/LandingPage/LandingPage";

const App = () => {
  return (
    <FirebaseAppProvider>
      <RemoteConfigProvider>
        <AuthProvider>
          <SnackbarProvider>
            <FreePatternModalProvider>
              <BrowserRouter>
                <Routes>
                  <Route
                    path="/"
                    element={
                      <SignedInCheck LandingPage={LandingPage} Main={Main} />
                    }
                  />
                  <Route
                    path="*"
                    element={
                      <SignedInCheck LandingPage={LandingPage} Main={Main} />
                    }
                  />
                  <Route path="/PatternMaker" element={<Main />} />
                  <Route path="/sp/*" element={<SinglePattern />} />
                </Routes>
              </BrowserRouter>
            </FreePatternModalProvider>
          </SnackbarProvider>
        </AuthProvider>
      </RemoteConfigProvider>
    </FirebaseAppProvider>
  );
};

export default App;
