import React, { createContext, useState, useEffect, useContext } from "react";
import { ANGLE_LINES, IMAGETYPE, MEASUREMENT_UNITS } from "../js/constants";
import BuildPattern from "../js/PatternBuilder";
import { RemoteConfigContext } from "../Context/RemoteConfigProvider";
import { getValue } from "firebase/remote-config";

export const PatternContext = createContext();

export const PatternInputProvider = (props) => {
  const remoteConfig = useContext(RemoteConfigContext);
  const [imagePrimaryDataURL, setImagePrimaryDataURL] = useState(null);
  const [imagePrimaryJimpError, setImagePrimaryJimpError] = useState(null);
  const [imageSecondaryJimpError, setImageSecondaryJimpError] = useState(null);
  const [imageSecondaryDataURL, setImageSecondaryDataURL] = useState(null);
  const [imageType, setImageType] = useState(IMAGETYPE.SINGLE);
  const [lastOddPage, setLastOddPage] = useState(249);
  const [height, setHeight] = useState(220);
  const [angleLine, setAngleLine] = useState(ANGLE_LINES.NONE);
  const [isInkSaver, setIsInkSaver] = useState(false);
  const [measurementUnit, setMeasurementUnit] = useState(
    MEASUREMENT_UNITS[0].value
  );

  const { Jimp } = window;

  useEffect(() => {
    const testJimpRead = async () => {
      try {
        await Jimp.read(imagePrimaryDataURL);
        setImagePrimaryJimpError(null);
      } catch (error) {
        setImagePrimaryJimpError(error.message);
      }
    };
    if (imagePrimaryDataURL !== null) {
      testJimpRead();
    }
  }, [Jimp, imagePrimaryDataURL]);

  useEffect(() => {
    const testJimpRead = async () => {
      try {
        await Jimp.read(imageSecondaryDataURL);
        setImageSecondaryJimpError(null);
      } catch (error) {
        setImageSecondaryJimpError(error.message);
      }
    };
    if (imageSecondaryDataURL !== null) {
      testJimpRead();
    }
  }, [Jimp, imageSecondaryDataURL]);

  const GetPattern = async () => {
    const value = getValue(remoteConfig, "PDF_PASSWORD");
    const pdfPassword = value._value;

    let imageSecondaryDataURLInput =
      imageType === IMAGETYPE.DOUBLE ? imageSecondaryDataURL : null;

    return await BuildPattern({
      angleLine,
      isInkSaver,
      imagePrimaryDataURL,
      imageSecondaryDataURL: imageSecondaryDataURLInput,
      height,
      lastOddPage,
      measurementUnit,
      subscribed: true,
      pdfPassword,
    });
  };

  const GetSamplePattern = async () => {
    let imageSecondaryDataURLInput =
      imageType === IMAGETYPE.DOUBLE ? imageSecondaryDataURL : null;

    return await BuildPattern({
      angleLine,
      isInkSaver,
      imagePrimaryDataURL,
      imageSecondaryDataURL: imageSecondaryDataURLInput,
      height,
      lastOddPage,
      measurementUnit,
      subscribed: false,
    });
  };

  const patternContext = {
    imagePrimaryDataURLState: [
      imagePrimaryDataURL,
      setImagePrimaryDataURL,
      imagePrimaryJimpError,
    ],
    imageSecondaryDataURLState: [
      imageSecondaryDataURL,
      setImageSecondaryDataURL,
      imageSecondaryJimpError,
    ],
    imageTypeState: [imageType, setImageType],
    lastOddPageState: [lastOddPage, setLastOddPage],
    heightState: [height, setHeight],
    angleLineState: [angleLine, setAngleLine],
    isInkSaverState: [isInkSaver, setIsInkSaver],
    measurementUnitState: [measurementUnit, setMeasurementUnit],
    GetPattern,
    GetSamplePattern,
  };

  return (
    <PatternContext.Provider value={patternContext}>
      {props.children}
    </PatternContext.Provider>
  );
};
