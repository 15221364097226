import React, { createContext, useContext, useEffect } from "react";
import { FirebaseAppContext } from "./FirebaseAppProvider";
import { getRemoteConfig, fetchAndActivate } from "firebase/remote-config";

export const RemoteConfigContext = createContext();

export const RemoteConfigProvider = (props) => {
  const firebaseApp = useContext(FirebaseAppContext);
  const remoteConfig = getRemoteConfig(firebaseApp);
  remoteConfig.settings.minimumFetchIntervalMillis = 3600000;

  useEffect(() => {
    const callFetchAndActivate = async () => {
      await fetchAndActivate(remoteConfig);
    };

    callFetchAndActivate();
  }, [remoteConfig]);

  return (
    <RemoteConfigContext.Provider value={remoteConfig}>
      {props.children}
    </RemoteConfigContext.Provider>
  );
};
